/**
 * eslint-disable react-hooks/rules-of-hooks
 *
 * @format
 */
import { Text, Picture } from '@components/ui'
import { cn } from '@components/helper'
import { useTimer } from 'react-timer-hook'
import { Autoplay, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { appendRefParameter } from '@lib/utils/tools'
import Icon from '@components/icons'
import { useRouter } from 'next/router'

import s from './Layout.module.css'
import 'swiper/css'
import 'swiper/css/effect-fade'
import { useEffect, useMemo, useState } from 'react'

const TopBanner = ({ data, shopCommon, className, pageType }) => {
  const { locale } = useRouter()
  const [realIndex, setRealIndex] = useState(0)
  const handleSlideChange = (swiper) => {
    const currentIndex = swiper.realIndex
    setRealIndex(swiper.realIndex)
    swiper.params.autoplay.delay =
      data.list[currentIndex]?.autoplayDelay || 3000
    swiper.autoplay.start()
  }

  const { setRelativeLink } = useRelativeLink()

  const [lPx, setLpx] = useState(0)

  const hasTag = useMemo(() => {
    return data?.tagTxt && data?.tagHref
  }, [data])

  function updateLpx() {
    const w = window?.innerWidth
    if (pageType == 'hes' || pageType == 'powerStation') {
      if (w >= 1440) {
        setLpx((w - 1280) / 2)
      } else if (w >= 1024 && w < 1440) {
        setLpx(80) // layer padding值
      } else if (w >= 768 && w < 1023) {
        setLpx(40)
      } else {
        setLpx(24)
      }
    } else {
      if (w >= 1920) {
        setLpx((w - 1680) / 2)
      } else if (w >= 1519 && w < 1920) {
        setLpx(120)
      } else if (w >= 1440 && w < 1519) {
        setLpx((w - 1280) / 2)
      } else if (w >= 1024 && w < 1440) {
        setLpx(80)
      } else if (w >= 768 && w < 1024) {
        setLpx(40)
      } else {
        setLpx(24)
      }
    }
  }

  useEffect(() => {
    updateLpx()
    window?.addEventListener('resize', updateLpx)
    return () => {
      window?.removeEventListener('resize', updateLpx)
    }
  }, [])

  const settings = {
    loop: true,
    slidesPerView: 1,
    centeredSlides: true,
    autoplay: {
      pauseOnMouseEnter: true,
      disableOnInteraction: true,
      delay: (data?.list?.length && data.list[0]?.autoplayDelay) || 3000,
    },
    modules: [Autoplay],
    onSlideChange: (swiper) => handleSlideChange(swiper),
  }

  if (data?.effect == 'fade') {
    settings['effect'] = 'fade'
    settings.modules.push(EffectFade)
  }

  return (
    <div id="TopBanner" className="relative">
      {data?.list?.length ? (
        <Swiper {...settings}>
          {data?.list?.map((item, index) => (
            <SwiperSlide key={index}>
              <Row
                data={item}
                shopCommon={shopCommon}
                index={index}
                className={className}
                pageType={pageType}
                setRelativeLink={setRelativeLink}
                hasTag={hasTag}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Row
          data={data}
          shopCommon={shopCommon}
          className={className}
          pageType={pageType}
          setRelativeLink={setRelativeLink}
          hasTag={hasTag}
        />
      )}

      {pageType == 'hes' || pageType == 'powerStation' ? (
        <>
          {data?.tagTxt && data?.tagHref && (
            <>
              <a
                style={{
                  display: lPx ? 'block' : 'none',
                  left: lPx + 'px',
                }}
                className={cn(
                  'absolute top-[50%] z-[2] translate-y-[-50%] text-white hover:underline md:!hidden md:text-[12px]',
                  pageType == 'hes' || pageType == 'powerStation'
                    ? 'text-[16px] font-[600]'
                    : 'text-[14px] font-[500] md:!hidden',
                  {
                    ['!text-black']:
                      (data?.list?.length
                        ? data?.list?.[realIndex]?.textTheme
                        : data?.textTheme) === 'light',
                  }
                )}
                href={appendRefParameter({
                  url: setRelativeLink({ link: data?.tagHref }),
                  refValue: 'naviMenu_pps',
                })}
                dangerouslySetInnerHTML={{ __html: data?.tagTxt }}
              ></a>
            </>
          )}
        </>
      ) : (
        <>
          <div className="layer_max absolute left-0 top-0 z-[-1] md:hidden">
            <div className="content" id="contentEle"></div>
          </div>
          {!data?.hideSolixLogo && (
            <a
              style={{
                display: lPx ? 'block' : 'none',
                left: lPx + 'px',
              }}
              className={cn(
                'absolute top-[50%] z-[2] translate-y-[-50%] text-[14px] font-[500] text-white hover:underline md:!hidden md:text-[12px]',
                {
                  ['!text-black']:
                    (data?.list?.length
                      ? data?.list[realIndex]?.textTheme
                      : data?.textTheme) === 'light',
                },
                data?.list?.[realIndex]?.textTheme === 'light' && '!text-[#777]'
              )}
              href={appendRefParameter({
                url: setRelativeLink({
                  link: `${locale === 'us' ? '' : `/${locale}`}/anker-solix`,
                }),
                refValue: 'naviMenu',
              })}
            >
              <div className="flex w-[100px]">
                <Icon
                  iconKey="anker"
                  width="82"
                  className={cn(
                    data?.list?.[realIndex]?.logoTheme === 'light'
                      ? 'fill-[#fff]'
                      : 'fill-[#00a9e1]',
                    'transition-all duration-[0.2s]'
                  )}
                />
                <Icon
                  iconKey="power"
                  className={cn(
                    'ml-1 w-[72px] [&_path]:transition-all [&_path]:duration-[0.2s]',
                    data?.list?.[realIndex]?.logoTheme === 'light' &&
                      '[&_path]:fill-[#fff]'
                  )}
                />
              </div>
            </a>
          )}
        </>
      )}
    </div>
  )
}

export default TopBanner

const Row = ({
  data,
  shopCommon,
  className,
  pageType,
  index = 0,
  setRelativeLink,
  hasTag,
}) => {
  return (
    <div
      style={{
        background:
          pageType == 'charger' && data?.bg
            ? `linear-gradient(to right, ${data?.bg?.from}, ${data?.bg?.to})`
            : pageType !== 'charger' && data?.hesBg
            ? `linear-gradient(to right, ${data?.hesBg?.from}, ${data?.hesBg?.to})`
            : 'linear-gradient(to right, #395EBD, #EC6B33)',
      }}
      className={cn(
        'xs-x:px-[16px] relative box-border w-full  leading-[1.4] text-white xs:px-[10px] md:text-[12px]',
        { ['!text-black']: data?.theme === 'light' },
        pageType == 'charger'
          ? 'layer_max h-[40px] text-[14px] md:text-center'
          : 'layer h-[60px] text-[16px]'
      )}
      id={`TopBanner_${index}`}
    >
      <div
        className={cn(
          'content relative z-[2] flex items-center justify-center md:flex md:items-center md:justify-center',
          className,
          pageType === 'charger' ? 'gap-[20px]' : 'gap-10 md:gap-5'
        )}
      >
        <div
          className={cn('flex w-fit items-center md:flex-col md:items-start', {
            ['md:!flex-row']: data?.row,
          })}
        >
          {data?.icon && (
            <Picture
              className="mr-[4px] h-[14px] w-[14px] md:m-0"
              source={`${data?.icon} 769`}
              alt={data?.alt || 'icon'}
            />
          )}
          <Text
            html={data?.left}
            className={cn('font-[500] text-white', {
              ['!text-[#1D1D1F]']: data?.textTheme === 'light',
            })}
            variant="paragraph"
          />
          {data?.right && (
            <div className="flex">
              <a
                href={appendRefParameter({
                  url: setRelativeLink({ link: data?.to }),
                  refValue:
                    pageType === 'charger' ? 'naviMenu' : 'naviMenu_pps',
                })}
                className={cn(
                  'md:mt-[6px]',
                  { ['md:!mt-0']: data?.row },
                  pageType == 'charger'
                    ? 'ml-[12px] font-[500]'
                    : 'ml-[30px] font-bold underline md:ml-1'
                )}
                variant="paragraph"
                style={{
                  color:
                    data?.rightColor ||
                    (data?.textTheme === 'light' ? '#1D1D1F' : '#fff'),
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: data?.right }} />
                {data?.showRightIcon && (
                  <ChevronRightIcon
                    width={12}
                    className="inline-block"
                    style={{
                      fill:
                        data?.rightColor ||
                        (data?.textTheme === 'light' ? '#1D1D1F' : '#fff'),
                    }}
                  />
                )}
              </a>
            </div>
          )}
        </div>
        <CountDown data={{ ...shopCommon.countDown, ...data }} />
      </div>
      {pageType !== 'charger' && data?.imgUrl && (
        <Picture
          source={`${data?.imgUrl}, ${data?.imgUrlMobile || data?.imgUrl} 768`}
          className="w-full h-full object-cover absolute left-0 top-0 z-[1]"
          imgClassName="w-full h-full object-cover"
        />
      )}
    </div>
  )
}

const CountDown = ({ data, className }) => {
  if (!data.endTime || new Date() > new Date(data.endTime)) return null

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(data.endTime),
  })

  return (
    <div className={cn(className)}>
      {data?.onlyNumber ? (
        <div className="flex">
          <Text
            html={data?.countDownFormat
              ?.replace('$days', String(days).padStart(2, '0'))
              ?.replace('$hours', String(hours).padStart(2, '0'))
              ?.replace('$minutes', String(minutes).padStart(2, '0'))
              ?.replace('$seconds', String(seconds).padStart(2, '0'))
              ?.replace(
                '$totalHours',
                String(24 * days + hours).padStart(2, '0')
              )}
            variant="paragraph"
            className={cn(
              'text-[14px] font-[500] md:text-[12px]',
              data?.textTheme === 'light' ? 'text-[#1D1D1F]' : 'text-[#fff]'
            )}
          />
        </div>
      ) : (
        <div className="mt-[8px] flex">
          {!data.hideDays && !data.countdown24 && days > 0 && (
            <Item
              time={days}
              textTheme={data.textTheme}
              copy={data.days}
              theme={data.theme}
            />
          )}

          <Item
            time={
              data.countdown24 || !data.hideDays
                ? (days * 24 + hours) % 24
                : days * 24 + hours
            }
            copy={data.hours}
            theme={data.theme}
            textTheme={data.textTheme}
          />
          <Item
            time={minutes}
            textTheme={data.textTheme}
            copy={data.minutes}
            theme={data.theme}
          />
          <Item
            time={seconds}
            copy={data.seconds}
            theme={data.theme}
            textTheme={data.textTheme}
            last={true}
          />
        </div>
      )}
    </div>
  )
}

const Item = ({ time, copy, theme, textTheme, last = false }) => (
  <div className="flex flex-col items-center">
    <div className="flex items-center">
      <div
        suppressHydrationWarning={true}
        className={cn(s.code)}
        style={{ color: theme?.primary || 'black', backgroundColor: theme?.bg }}
      >
        {time < 10 ? `0${time}` : time}
      </div>
      {!last && <span className={cn(s.colon, theme?.bg)}>:</span>}
    </div>
    <div
      style={{ color: theme?.primary || 'white' }}
      className={cn('pt-[6px] text-[10px] text-white', {
        ['pr-2 md:pr-1']: !last,
        ['!text-[#1D1D1F]']: textTheme === 'light',
      })}
    >
      {copy}
    </div>
  </div>
)
